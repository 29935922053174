import './App.css';
import CvPage from './components/cv/CvPage';


function App() {
  return (
    <div className="App">
      <CvPage/>
    </div>
  );
}

export default App;
